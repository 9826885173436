.slide_2 {
  /* background-image: url('https://growgreencontact.s3.us-west-1.amazonaws.com/4.png'); */
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("../../asserts/slide/1.webp");

  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.slide_1 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("../../asserts/slide/3.webp");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.slide_3 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("../../asserts/slide/2.webp");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.slide_4 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("https://growgreencontact.s3.us-west-1.amazonaws.com/4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.slide_5 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("../../asserts/slide/3.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.slide_6 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("https://growgreencontact.s3.us-west-1.amazonaws.com/6.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.slide_7 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("https://growgreencontact.s3.us-west-1.amazonaws.com/7.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.coco_products_1 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/5.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_2 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_3 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/b.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_4 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/CoCoPeatGrowBags/kkk.jpg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_5 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/y.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.videoheader {
  /* position: relative; */
  /* height: 80vh !important; */
  /* min-height: 25rem; */
  /* width: 100%; */
  /* overflow: hidden; */
  /* margin-top: 50px; */
  /* height: 560px; */
}

.videoheader video {
  /* position: absolute; */
  /* top: 50%;
    left: 50%; */
  /* min-width: 100%; */
  /* min-height: 100%; */
  width: 100%;
  /* height: 400px; */
  z-index: 0;
  /* height: 500px; */
  /* -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%); */
  /* transform: translateX(-50%) translateY(-50%); */
}

.videoheader .container {
  position: relative;
  z-index: 2;
}

.main-div-bk {
  background: linear-gradient(
      0deg,
      rgba(181, 181, 181, 0.1),
      rgba(189, 189, 189, 0.1)
    ),
    url("../../asserts/bk.jpg");

  /* background-image: url('../../asserts/bk.jpg'); */
}
@media (pointer: coarse) and (hover: none) {
  .videoheader video {
    display: block;
  }
}
.zoom:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
#scroll-down-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 95vh;
}

.mouse {
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  border: 2px solid #6e6e6e;
  height: 40px;
  width: 20px;
  position: relative;
}

.move {
  position: absolute;
  background-color: #6e6e6e;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: move 2s linear infinite;
}

@keyframes move {
  0% {
    transform: translate(-50%, 2px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 15px);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, 30px);
    opacity: 0;
  }
}
@media screen and (max-width: 903px) {
  #scroll-down-animation {
    display: none !important;
  }
}

.snip1533 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #9e9e9e;
  font-size: 16px;
  margin: 35px 10px 10px;
  max-width: 310px;
  min-width: 250px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border-top: 5px solid #208501;
  height: 330px;
}

.snip1533 *,
.snip1533 *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.snip1533 figcaption {
  padding: 13% 10% 12%;
}

.snip1533 figcaption:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #208501;

  font-size: 32px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  position: absolute;
  top: -30px;
  width: 60px;
}

.snip1533 h3 {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 10px 0 5px;
}

.snip1533 h4 {
  font-weight: 400;
  margin: 0;
  opacity: 0.5;
  font-size: 18px;
}

.snip1533 blockquote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
  font-size: 15px;
}

.why-us-img{
  width: 50px;
  height: 50px;
  margin-top: 15px;
}
.why-us-h4{
  font-size: 1.3rem;
  font-weight: 600;
  margin: 2px 0px ;
  padding: 10px 0px ;
}
.why-us-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  padding: 5px;
  margin-top: 20px;
  transform:
  perspective(800px)
  rotateY(0deg);
transition: transform 1s ease 0s;
border-radius: 4px;
box-shadow:
  rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
  rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
  rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
  rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}
.why-us-card p{

  text-align: justify;
  padding: 10px;
}





.why-us-card:hover{
  transform: perspective(800px) rotateY(-16deg);

}



