
.cli_n{
    color: black;
    font-weight: 500;
}
.cli_n-trans{
    color: rgb(255, 255, 255);
    font-weight: 500;
}
.center {
	text-align: center;
}

.nav-wrapper-trans {
	display: flex;
	position: relative;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	margin: auto;
	height: 80px;
	padding: 0 25px;
	z-index: 2;
	background: transparent;
	color: white;
	
}

.nav-wrapper {
	display: flex;
	position: relative;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	margin: auto;
	height: 80px;
	padding: 0 25px;
	z-index: 2;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	height: 30px !important;
}

.nav-tabs {
	display: flex;
	font-weight: 600;
	font-size: 18px;
	list-style: none;
    border-bottom: none !important;
	
}
.nav-tabs-trans {
	display: flex;
	font-weight: 600;
	font-size: 18px;
	list-style: none;
    border-bottom: none !important;
	flex-direction: column;
}

.nav-tab:not(:last-child) {
	padding: 10px 25px;
	margin: 0;
	/* border-right: 1px solid #eee; */
}

.nav-tab:last-child {
	padding: 10px 0 0 25px;
}

.nav-tab,
.menu-btn {
	cursor: pointer;
}

.hidden {
	display: none;
}

@media screen and (max-width: 903px) {
	.nav-container {
		position: fixed;
		display: none;
		overflow-y: auto;
		z-index: -1;
		top: 0;
		right: 0;
		width: 280px;
		height: 100%;
		background: #fff;
		box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
	}
	#hero .carousel-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: absolute;
		bottom: 60px;
		top: 70px;
		left: 55px;
		right: 55px;
		flex-direction: column !important;
	  }
	.nav-container-trans {
		
		display: block;
		overflow-y: auto;
		z-index: -1;
	
		width: 280px;
		height: 100%;
		
	}

	.nav-tabs {
		flex-direction: column;
		/* align-items: flex-end; */
		margin-top: 80px;
		width: 100%;
        border-bottom: none !important;

	}

	.nav-tab:not(:last-child) {
		padding: 20px 25px;
		margin: 0;
		border-right: unset;
	}

	.nav-tab:last-child {
		padding: 15px 25px;
	}

	.menu-btn {
		position: relative;
		display: block;
		margin: 0;
		width: 45px;
		height: 33px;
		cursor: pointer;
		z-index: 2;
		padding: 10px;
		border-radius: 10px;
        /* background-color: #208501; */
	}

	.menu-btn .menu {
		display: block;
		width: 100%;
		height: 2px;
		border-radius: 2px;
		background: #111;
	}

	.menu-btn .menu:nth-child(2) {
		margin-top: 4px;
		opacity: 1;
	}

	.menu-btn .menu:nth-child(3) {
		margin-top: 4px;
	}

	#menuToggle:checked + .menu-btn .menu {
		transition: transform 0.2s ease;
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(1) {
		transform: translate3d(0, 6px, 0) rotate(45deg);
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(2) {
		transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
		opacity: 0;
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(3) {
		transform: translate3d(0, -6px, 0) rotate(-45deg);
	}

	#menuToggle:checked ~ .nav-container {
		z-index: 1;
		display: flex;
		animation: menu-slide-left 0.3s ease;
	}
	@keyframes menu-slide-left {
		0% {
			transform: translateX(200px);
		}
		to {
			transform: translateX(0);
		}
	}
}


.footer-cred{
	color: rgb(255, 255, 255);
                    font-size: 12px;
                    font-weight: 600;
}
.footer-cred2{
	color: rgb(255, 255, 255); font-size: 12px;
}