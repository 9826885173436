@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(219, 219, 219, 0.834);
  z-index: 1;
}

.spinner {
  width: 100px;
  height: 100px;
  border: 8px solid;
  border-color: #238604 transparent #238604 transparent;
  border-radius: 50%;
  -webkit-animation: spin-anim 1.2s linear infinite;
          animation: spin-anim 1.2s linear infinite;
}

@-webkit-keyframes spin-anim {
  0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
  }
}

@keyframes spin-anim {
  0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
  }
}
/**
* Template Name: Company - v2.0.1
* Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: #4d4643;
  font-family: 'Poppins', sans-serif !important;

}

a {
  color: #208501;
}

a:hover {
  color: #2ae149;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif !important;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.prodcu-com{
  display: flex;
  flex-direction: row;
}
.btn-success{
  background: #208501;
}
.btn-success:hover{
  background: #315a24;
}
.btn-info{
  background: #9b9b9b;
  border: none;
}
.btn-info:hover{
  background: #2c2c2c;
  border: none;
}
.back-to-top {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  right: 15px;
  bottom: 15px;
  background: #208501;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.back-to-top:hover {
  color: #fff;
  background: #2ae149;
  transition: 0.3s ease-in-out;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

#header .logo a {
  color: #111;
}

#header .logo a span {
  color: #208501;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #111;
  transition: 0.3s;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #208501;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 15px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-top: 2px solid #208501;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #111;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: #208501;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #208501;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #111;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #208501;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Header Social Links
--------------------------------------------------------------*/
.header-social-links {
  padding-left: 14px;
  margin-left: 20px;
  border-left: 1px solid #c4c4c4;
}

.header-social-links a {
  color: #a0a0a0;
  padding-left: 6px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

.header-social-links a:hover {
  color: #208501;
}

@media (max-width: 768px) {
  .header-social-links {
    padding: 0 48px 0 0;
    border-left: 0;
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  position: relative;
}

#hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

#hero .carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 60px;
  top: 70px;
  left: 55px;
  right: 55px;
}

#hero .carousel-content {
  /* background: rgba(4, 4, 4, 0.7); */
  padding: 20px;
  color: #fff;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  /* border-top: 2px solid #208501; */
}
#hero .carousel-content-trans {
  background: transparent;
  padding: 20px;
  color: #fff;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
}

#hero .carousel-content h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 10px;
  line-height: 50px;
}

#hero .btn-get-started {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #ffffff;
}

#hero .btn-get-started:hover {
  background: #208501;
  color: #fff;
  text-decoration: none;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-left,
#hero .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev, #hero .carousel-control-next {
  width: 10%;
  opacity: 1;
}

#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 36px;
  line-height: 1;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  padding: 10px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
}

#hero .carousel-control-next-icon:hover, #hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: .6;
  transition: 0.3s;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background: #208501;
}

@media (min-width: 1024px) {
  #hero .carousel-content {
    width: 50%;
  }
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-width: 992px) {
  #hero .carousel-container {
    top: 58px;
  }
  #hero .carousel-content h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  #hero .carousel-content p {
    font-size: 15px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f7f7f7;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #208501;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #5f5f5f;
  min-height: 40px;
  /* margin-top: 72px; */
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 300;
  color: #fff;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
}

.breadcrumbs ol a {
  color: #fff;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #fff;
  content: "/";
}

@media (max-width: 991px) {
  .breadcrumbs {
    margin-top: 58px;
  }
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  width: 100%;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.services .icon-box h4 a {
  color: #111;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}

.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 50px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #4d4643;
  transition: all 0.3s;
  border-radius: 4px;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #208501;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #111;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #444444;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #2b2b2b;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #208501;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
  color: #57e76f;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-wrap {
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

.clients .client-logo {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  background: #fff;
  height: 100px;
}

.clients .client-logo img {
  transition: all 0.3s ease-in-out;
  height: 36px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.clients .client-logo:hover img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about-us .content h2 {
  font-weight: 700;
  font-size: 42px;
  line-height: 60px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.about-us .content h3 {
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
}

.about-us .content ul {
  list-style: none;
  padding: 0;
}

.about-us .content ul li {
  padding: 10px 0 0 28px;
  position: relative;
}

.about-us .content ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #208501;
}

.about-us .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
}

.team .member .social a {
  transition: color 0.3s;
  color: #111;
  margin: 0 10px;
  padding-top: 8px;
  display: inline-block;
}

.team .member .social a:hover {
  color: #208501;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #111;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #b4aca8;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #847872;
}

.team .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 50px;
  display: block;
  background: none;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #111;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e7e7e7;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: .9s;
  background-color: #208501;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.3s;
}

.features .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}

.features .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}

.features .icon-box h3 a {
  color: #111;
  transition: ease-in-out 0.3s;
}

.features .icon-box h3 a:hover {
  color: #208501;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #847872;
  background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: #208501;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #4d4643;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #208501;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}

.pricing .btn-buy {
  background: #208501;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #2ae149;
}

.pricing .featured h3 {
  color: #fff;
  background: #208501;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #208501;
  color: #fff;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: lightgray;
}

.faq .faq-list .icon-show, .faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: #208501;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: #dddddd;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.contact .info {
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #208501;
  float: left;
  width: 44px;
  height: 44px;
  border: 1px solid #208501;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #111;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #444444;
}

.contact .info:hover i {
  background: #208501;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #208501;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #208501;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #2ae149;
}

@-webkit-keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding: 40px 0;
}

.portfolio-details .portfolio-details-container {
  position: relative;
}

.portfolio-details .portfolio-details-carousel {
  position: relative;
  z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav, .portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #208501 !important;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -70px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
  width: 50%;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0 0 0 0;
}

@media (max-width: 768px) {
  .portfolio-details .portfolio-info {
    position: static;
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 400px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #111;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #208501;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #aaaaaa;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 14px;
  padding-right: 4px;
}

.blog .entry .entry-meta a {
  color: #5e5e5e;
  font-size: 14px;
  display: inline-block;
  line-height: 20px;
  transition: 0.3s;
}

.blog .entry .entry-meta a:hover {
  color: #208501;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #208501;
  color: #fff;
  padding: 7px 25px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #1ed33c;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #4d4643;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote .quote-left {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote .quote-right {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #111;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #5e5e5e;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #8b8b8b;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #208501;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
}

.blog .blog-author h4 {
  margin-left: 140px;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
}

.blog .blog-author .social-links {
  margin: 0 0 5px 140px;
}

.blog .blog-author .social-links a {
  color: #777777;
}

.blog .blog-author p {
  margin-left: 140px;
  font-style: italic;
  color: #bfb9b6;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  width: 50px;
}

.blog .blog-comments .comment h5 {
  margin-left: 65px;
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #4d4643;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #208501;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #111;
}

.blog .blog-comments .comment time {
  margin-left: 65px;
  display: block;
  font-size: 14px;
  color: #777777;
  margin-bottom: 5px;
}

.blog .blog-comments .comment p {
  margin-left: 65px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 0;
  padding: 20px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #57e76f;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 0;
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #57e76f;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  padding: 8px 20px;
  border: 0;
  border-radius: 4px;
  background-color: #208501;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #1ed33c;
}

.blog .blog-pagination {
  color: #444444;
}

.blog .blog-pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.blog .blog-pagination li {
  border: 1px solid white;
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li.active {
  background: white;
}

.blog .blog-pagination li a {
  color: #b4aca8;
  padding: 7px 16px;
  display: inline-block;
}

.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
  background: #208501;
  border: 1px solid #208501;
  border-radius: 4px;
}

.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .blog-pagination li.disabled {
  background: #fff;
  border: 1px solid white;
}

.blog .blog-pagination li.disabled i {
  color: #f6f5f5;
  padding: 10px 16px;
  display: inline-block;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #111;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
  border-radius: 4px;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #208501;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.blog .sidebar .search-form form button:hover {
  background: #1ed33c;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #2b2b2b;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #208501;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: black;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #208501;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #777777;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #515151;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #dddddd;
  display: inline-block;
  transition: 0.3s;
  border-radius: 4px;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #208501;
  background: #208501;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #fff;
  font-size: 14px;
  /* background-image: url('./asserts/footerbg.png'); */
  background:linear-gradient(0deg, rgba(48, 47, 47, 0.7), rgba(32, 32, 32, 0.7)), url(/static/media/footernew.ace191e7.jpeg);
  background-size: cover;

}

#footer .footer-top {
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 22px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif !important;
  color: white;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ffffff;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: white;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #fff;
}

#footer .footer-newsletter {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px 0 0 4px;
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -4px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #208501;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #2ae149;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #fff;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #208501;
  color: #fff;
  text-decoration: none;
}
.prodcut-img-main{
  /* height: 80px;
  width: 150px; */
}
.Modal{
  z-index: 1000;
}

.tab-view-img{
  height: 220px !important;
  width: 310px;
}

.react-tabs__tab--selected{
   background: #5f5f5f!important;
      border-color: #aaa !important;
    border-radius: 5px 5px 0 0 !important;
    color: white !important;
    font-weight: 700 !important;
}
.react-tabs__tab{
  padding: 10px 20px !important; 
}



.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.about-us-bk-header{
  padding: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.slect-nac{
  color: red !important;
}




.card-hover {
  width: 360px;
  height: 500px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);
}

.card-hover:has(.card-hover__link:hover) .card-hover__extra {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}

.card-hover:hover .card-hover__content {
  background-color: #DEE8C2;
  bottom: 100%;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  padding: 50px 60px;
  transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

.card-hover:hover .card-hover__link {
  opacity: 1;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  transition: all 0.3s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

.card-hover:hover img {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: 0.35s 0.1s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

.card-hover__content {
  /* width: 100%; */
  text-align: center;
  background-color: #86B971;
  padding: 0 60px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: all 0.35s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
  will-change: bottom, background-color, transform, padding;
  z-index: 1;
}

.card-hover__content::before,
.card-hover__content::after {
  content: '';
  width: 100%;
  height: 120px;
  background-color: inherit;
  position: absolute;
  left: 0;
  z-index: -1;
}

.card-hover__content::before {
  top: -80px;
  -webkit-clip-path: ellipse(60% 80px at bottom center);
          clip-path: ellipse(60% 80px at bottom center);
}

.card-hover__content::after {
  bottom: -80px;
  -webkit-clip-path: ellipse(60% 80px at top center);
          clip-path: ellipse(60% 80px at top center);
}

.card-hover__title {
  font-size: 1.5rem;
  margin-bottom: 1em;
}

.card-hover__title span {
  color: #2d7f0b;
}

.card-hover__text {
  font-size: 0.75rem;
}

.card-hover__link {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translate(-50%, 10%);
          transform: translate(-50%, 10%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  color: #2d7f0b;
  opacity: 0;
  padding: 10px;
  transition: all 0.35s;
}

.card-hover__link:hover svg {
  -webkit-transform: translateX(4px);
          transform: translateX(4px);
}

.card-hover__link svg {
  width: 18px;
  margin-left: 4px;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.card-hover__extra {
  height: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  background-color: #86b971;
  padding: 80px;
  bottom: 0;
  z-index: 0;
  color: #dee8c2;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  will-change: transform;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}

.card-hover__extra span {
  color: #2d7f0b;
}

.img-card-new {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: 0.35s 0.35s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
}



.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

.card_image {
  position: relative;
  max-height: 350px;
}

.card_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.card_image_abt {
  position: relative;
  max-height: 550px;
}

.card_image_abt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_price {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 0.25rem;
  background-color: #c89b3f;
  font-size: 18px;
  font-weight: 700;
}

.card_price span {
  font-size: 12px;
  margin-top: -2px;
}

.note {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 4px 8px;
  border-radius: 0.25rem;
  background-color: #c89b3f;
  font-size: 14px;
  font-weight: 700;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 100%;
  }
}



.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  position: relative;
  padding: 16px 12px 32px 24px;
  margin: 16px 8px 8px 0;
  max-height: 290px;
  overflow-y: scroll;
}

.card_content::-webkit-scrollbar {
  width: 8px;
}

.card_content::-webkit-scrollbar-track {
  box-shadow: 0;
  border-radius: 0;
}

.card_content::-webkit-scrollbar-thumb {
  background: #c89b3f;
  border-radius: 15px;
}

.card_title {
  position: relative;
  margin: 0 0 24px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.card_title::after {
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #c89b3f;
  content: "";
}

hr {
  margin: 24px auto;
  width: 50px;
  border-top: 2px solid #c89b3f;
}

.card_text p {
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 1.5;
}

.card_text p:last-child {
  margin: 0;
}



.modified-hero-section {
  align-items: flex-start;
  /* background-image: linear-gradient(15deg, #0f4667 0%, #2a6973 150%); */
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}

.modified-card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}

@media (min-width: 540px) {
  .modified-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .modified-card-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.modified-card {
  list-style: none;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 250px;
}

.modified-card:before {
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.modified-card__background {
  background-size: cover;
  background-position: center;
  border-radius: var(--spacing-l);
  bottom: 0;
  -webkit-filter: brightness(0.75) saturate(1.2) contrast(0.85);
          filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(1) translateZ(0);
          transform: scale(1) translateZ(0);
  transition: -webkit-filter 200ms linear, -webkit-transform 200ms linear;
  transition: filter 200ms linear, transform 200ms linear;
  transition: filter 200ms linear, transform 200ms linear, -webkit-filter 200ms linear, -webkit-transform 200ms linear;
}

.modified-card:hover .modified-card__background {
  -webkit-transform: scale(1.05) translateZ(0);
          transform: scale(1.05) translateZ(0);
}

.modified-card-grid:hover > .modified-card:not(:hover) .modified-card__background {
  -webkit-filter: brightness(0.5) saturate(0) contrast(1) blur(8px);
          filter: brightness(0.5) saturate(0) contrast(1) blur(8px);
}

.modified-card__content {
  left: 0;
  padding: var(--spacing-l);
  position: absolute;
  top: 0;
}

.modified-card__category {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.modified-card__heading {
  color: var(--text-lighter);
  font-size: 1.5rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
padding: 10px;
  color: #ececec;
}


















.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
}

.blog-card a {
  color: inherit;
}

.blog-card a:hover {
  color: #5ad67d;
}

.blog-card:hover .photo {
  -webkit-transform: scale(1.3) rotate(3deg);
          transform: scale(1.3) rotate(3deg);
}

.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
}

.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}

.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}

.blog-card .details a {
  -webkit-text-decoration: dotted underline;
          text-decoration: dotted underline;
}

.blog-card .details ul li {
  display: inline-block;
}

.blog-card .details .author:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "\f007";
}

.blog-card .details .date:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "\f133";
}

.blog-card .details .tags ul:before {
  font-family: FontAwesome;
  content: "\f02b";
  margin-right: 10px;
}

.blog-card .details .tags li {
  margin-right: 2px;
}

.blog-card .details .tags li:first-child {
  margin-left: -4px;
}

.blog-card .description {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.blog-card .description h1,
.blog-card .description h2 {
  font-family: Poppins, sans-serif;
}

.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}

.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}

.blog-card .description .read-more {
  text-align: right;
}

.blog-card .description .read-more a {
  color: #5ad67d;
  display: inline-block;
  position: relative;
}

.blog-card .description .read-more a:after {
  content: "\f061";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}

.blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
}

.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}

.blog-card p:first-of-type {
  margin-top: 1.25rem;
}

.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #5ad67d;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}

.blog-card:hover .details {
  left: 0%;
}

@media (min-width: 640px) {

  .blog-card {
    flex-direction: row;
    max-width: 1300px;
  }

  .blog-card .meta {
    flex-basis: 56%;
    height: auto;
  }

  .blog-card .description:before {
    -webkit-transform: skewX(-3deg);
            transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .blog-card.alt {
    flex-direction: row-reverse;
  }

  .blog-card.alt .description:before {
    left: inherit;
    right: -10px;
    -webkit-transform: skew(3deg);
            transform: skew(3deg);
  }

  .blog-card.alt .details {
    padding-left: 25px;
  }
}






















.background-texture {
  background: linear-gradient(to top, #0f0140e6, rgba(255, 214, 117, 0.9));
  background-size: cover;
  width: 100%;
  height: 1000px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.carousel {
  overflow-x: hidden !important;
  width: 100%;
  overflow-x: scroll;
  padding: 30px;
  padding-top: 20px;
  position: relative;
  box-sizing: border-box;
}

.carousel__container {
  white-space: nowrap;
  margin: 20px 0px;
  padding-bottom: 10px;
  display: inline-block;
}

.categories__title {
 color: rgb(77, 55, 102);
  font-size: 28px;
  position: absolute;
  padding-left: 30px;
}

.carousel-item {
  width: 200px;
  height: 250px;
  border-radius: 20px;
  background-color: #aaaaaa;
  overflow: hidden;
  margin-right: 10px;
  margin-top: 70px;
  display: inline-block;
  cursor: pointer;
  transition: 1000ms all;
  -webkit-transform-origin: center left;
  transform-origin: center left;
  position: relative;
}

.carousel-item:hover ~ .carousel-item {
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}

.carousel__container:hover .carousel-item {
  opacity: 0.3;
}

.carousel__container:hover .carousel-item:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 1;
}

.carousel-item__img {
  width: 200px;
  height: 250px;
  object-fit: cover;
}

.carousel-item__details {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  font-size: 10px;
  opacity: 0;
  transition: 450ms opacity;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.carousel-item__details:hover {
  opacity: 1;
}

.carousel-item__details span {
  /* width: 10px;
  height: 10px; */
  font-size: 0.9rem;
  color: #2ecc71;
  /* background-color: white; */
}

.carousel-item__details .controls {
  padding-top: 180px;
}

.carousel-item__details .carousel-item__details--title,
.carousel-item__details--subtitle {
  color: #fff;
  margin: 5px 0;
}
.contatc-prod{
  margin-left: 20px ;
}
@media (max-width: 600px) {
  .carousel__container {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .prodcu-com{
    display: flex;
    flex-direction: column;
  }
  .contatc-prod{
    margin-left: 0px !important;
    margin-top: 10px;
  }
}
.tab-view-img{
  box-shadow: 3px 3px 3px rgb(180, 180, 180);
  border-radius: 8px;
}
.slide_2 {
  /* background-image: url('https://growgreencontact.s3.us-west-1.amazonaws.com/4.png'); */
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url(/static/media/1.d6ef9c89.webp);

  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.slide_1 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url(/static/media/3.7fb01284.webp);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.slide_3 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url(/static/media/2.bd7a7666.webp);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.slide_4 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("https://growgreencontact.s3.us-west-1.amazonaws.com/4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.slide_5 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url(/static/media/3.7fb01284.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.slide_6 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("https://growgreencontact.s3.us-west-1.amazonaws.com/6.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.slide_7 {
  background: linear-gradient(
      0deg,
      rgba(48, 47, 47, 0.4),
      rgba(32, 32, 32, 0.4)
    ),
    url("https://growgreencontact.s3.us-west-1.amazonaws.com/7.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.coco_products_1 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/5.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_2 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/coconuthuskchips/3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_3 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/CocoPeatDiscs/b.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_4 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/CoCoPeatGrowBags/kkk.jpg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.coco_products_5 {
  background-image: url("https://growgreencontact.s3.us-west-1.amazonaws.com/products/Planterbags/y.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.videoheader {
  /* position: relative; */
  /* height: 80vh !important; */
  /* min-height: 25rem; */
  /* width: 100%; */
  /* overflow: hidden; */
  /* margin-top: 50px; */
  /* height: 560px; */
}

.videoheader video {
  /* position: absolute; */
  /* top: 50%;
    left: 50%; */
  /* min-width: 100%; */
  /* min-height: 100%; */
  width: 100%;
  /* height: 400px; */
  z-index: 0;
  /* height: 500px; */
  /* -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%); */
  /* transform: translateX(-50%) translateY(-50%); */
}

.videoheader .container {
  position: relative;
  z-index: 2;
}

.main-div-bk {
  background: linear-gradient(
      0deg,
      rgba(181, 181, 181, 0.1),
      rgba(189, 189, 189, 0.1)
    ),
    url(/static/media/bk.0b59da04.jpg);

  /* background-image: url('../../asserts/bk.jpg'); */
}
@media (pointer: coarse) and (hover: none) {
  .videoheader video {
    display: block;
  }
}
.zoom:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
#scroll-down-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 95vh;
}

.mouse {
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  border: 2px solid #6e6e6e;
  height: 40px;
  width: 20px;
  position: relative;
}

.move {
  position: absolute;
  background-color: #6e6e6e;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: move 2s linear infinite;
          animation: move 2s linear infinite;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: translate(-50%, 2px);
            transform: translate(-50%, 2px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translate(-50%, 15px);
            transform: translate(-50%, 15px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translate(-50%, 30px);
            transform: translate(-50%, 30px);
    opacity: 0;
  }
}

@keyframes move {
  0% {
    -webkit-transform: translate(-50%, 2px);
            transform: translate(-50%, 2px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translate(-50%, 15px);
            transform: translate(-50%, 15px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translate(-50%, 30px);
            transform: translate(-50%, 30px);
    opacity: 0;
  }
}
@media screen and (max-width: 903px) {
  #scroll-down-animation {
    display: none !important;
  }
}

.snip1533 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #9e9e9e;
  font-size: 16px;
  margin: 35px 10px 10px;
  max-width: 310px;
  min-width: 250px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border-top: 5px solid #208501;
  height: 330px;
}

.snip1533 *,
.snip1533 *:before {
  box-sizing: border-box;
  transition: all 0.1s ease-out;
}

.snip1533 figcaption {
  padding: 13% 10% 12%;
}

.snip1533 figcaption:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #208501;

  font-size: 32px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  position: absolute;
  top: -30px;
  width: 60px;
}

.snip1533 h3 {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 10px 0 5px;
}

.snip1533 h4 {
  font-weight: 400;
  margin: 0;
  opacity: 0.5;
  font-size: 18px;
}

.snip1533 blockquote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
  font-size: 15px;
}

.why-us-img{
  width: 50px;
  height: 50px;
  margin-top: 15px;
}
.why-us-h4{
  font-size: 1.3rem;
  font-weight: 600;
  margin: 2px 0px ;
  padding: 10px 0px ;
}
.why-us-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  padding: 5px;
  margin-top: 20px;
  -webkit-transform:
  perspective(800px)
  rotateY(0deg);
          transform:
  perspective(800px)
  rotateY(0deg);
transition: -webkit-transform 1s ease 0s;
transition: transform 1s ease 0s;
transition: transform 1s ease 0s, -webkit-transform 1s ease 0s;
border-radius: 4px;
box-shadow:
  rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
  rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
  rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
  rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}
.why-us-card p{

  text-align: justify;
  padding: 10px;
}





.why-us-card:hover{
  -webkit-transform: perspective(800px) rotateY(-16deg);
          transform: perspective(800px) rotateY(-16deg);

}





.cli_n{
    color: black;
    font-weight: 500;
}
.cli_n-trans{
    color: rgb(255, 255, 255);
    font-weight: 500;
}
.center {
	text-align: center;
}

.nav-wrapper-trans {
	display: flex;
	position: relative;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	margin: auto;
	height: 80px;
	padding: 0 25px;
	z-index: 2;
	background: transparent;
	color: white;
	
}

.nav-wrapper {
	display: flex;
	position: relative;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	margin: auto;
	height: 80px;
	padding: 0 25px;
	z-index: 2;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	height: 30px !important;
}

.nav-tabs {
	display: flex;
	font-weight: 600;
	font-size: 18px;
	list-style: none;
    border-bottom: none !important;
	
}
.nav-tabs-trans {
	display: flex;
	font-weight: 600;
	font-size: 18px;
	list-style: none;
    border-bottom: none !important;
	flex-direction: column;
}

.nav-tab:not(:last-child) {
	padding: 10px 25px;
	margin: 0;
	/* border-right: 1px solid #eee; */
}

.nav-tab:last-child {
	padding: 10px 0 0 25px;
}

.nav-tab,
.menu-btn {
	cursor: pointer;
}

.hidden {
	display: none;
}

@media screen and (max-width: 903px) {
	.nav-container {
		position: fixed;
		display: none;
		overflow-y: auto;
		z-index: -1;
		top: 0;
		right: 0;
		width: 280px;
		height: 100%;
		background: #fff;
		box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
	}
	#hero .carousel-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: absolute;
		bottom: 60px;
		top: 70px;
		left: 55px;
		right: 55px;
		flex-direction: column !important;
	  }
	.nav-container-trans {
		
		display: block;
		overflow-y: auto;
		z-index: -1;
	
		width: 280px;
		height: 100%;
		
	}

	.nav-tabs {
		flex-direction: column;
		/* align-items: flex-end; */
		margin-top: 80px;
		width: 100%;
        border-bottom: none !important;

	}

	.nav-tab:not(:last-child) {
		padding: 20px 25px;
		margin: 0;
		border-right: unset;
	}

	.nav-tab:last-child {
		padding: 15px 25px;
	}

	.menu-btn {
		position: relative;
		display: block;
		margin: 0;
		width: 45px;
		height: 33px;
		cursor: pointer;
		z-index: 2;
		padding: 10px;
		border-radius: 10px;
        /* background-color: #208501; */
	}

	.menu-btn .menu {
		display: block;
		width: 100%;
		height: 2px;
		border-radius: 2px;
		background: #111;
	}

	.menu-btn .menu:nth-child(2) {
		margin-top: 4px;
		opacity: 1;
	}

	.menu-btn .menu:nth-child(3) {
		margin-top: 4px;
	}

	#menuToggle:checked + .menu-btn .menu {
		transition: -webkit-transform 0.2s ease;
		transition: transform 0.2s ease;
		transition: transform 0.2s ease, -webkit-transform 0.2s ease;
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(1) {
		-webkit-transform: translate3d(0, 6px, 0) rotate(45deg);
		        transform: translate3d(0, 6px, 0) rotate(45deg);
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(2) {
		-webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
		        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
		opacity: 0;
	}

	#menuToggle:checked + .menu-btn .menu:nth-child(3) {
		-webkit-transform: translate3d(0, -6px, 0) rotate(-45deg);
		        transform: translate3d(0, -6px, 0) rotate(-45deg);
	}

	#menuToggle:checked ~ .nav-container {
		z-index: 1;
		display: flex;
		-webkit-animation: menu-slide-left 0.3s ease;
		        animation: menu-slide-left 0.3s ease;
	}
	@-webkit-keyframes menu-slide-left {
		0% {
			-webkit-transform: translateX(200px);
			        transform: translateX(200px);
		}
		to {
			-webkit-transform: translateX(0);
			        transform: translateX(0);
		}
	}
	@keyframes menu-slide-left {
		0% {
			-webkit-transform: translateX(200px);
			        transform: translateX(200px);
		}
		to {
			-webkit-transform: translateX(0);
			        transform: translateX(0);
		}
	}
}


.footer-cred{
	color: rgb(255, 255, 255);
                    font-size: 12px;
                    font-weight: 600;
}
.footer-cred2{
	color: rgb(255, 255, 255); font-size: 12px;
}
table, tr, td {
    border: none;
    border-top: none !important;
}
.sidebar_wrap {
    background: #393f46;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 10;
    overflow: hidden;
    transition: width 0.8s ease-in-out;
    width : 240px;

}

.sidebar-top {
    padding-top: 3.5rem !important;
}

ul.sidebar {
    list-style-type: none;
}
.click{
    cursor: pointer;
}

.active_category {
    color: white !important;
    background-color: #323941 !important;
}
.topnav{
    z-index: 12;
}

.categorylink {
    color: #c9cbcc;
    cursor: pointer;
    width: 100%;
    margin-top: 30px;
    
}
.listitem{
    background-color: #32394188;
    padding : 14px 0px;
    border-bottom: 1px solid #464d551f;
}
.listitem:hover{
    background-color: #323941;
}

.sidebar-image{
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.wrapper-wx{
    padding-left: 245px;
    transition: padding 0.8s ease-in-out;
}
.sidebar-icon{
    font-size: 16px;
}

.bold-normal{
    font-weight: 600;
}

.filepond--root .filepond--image-preview {
    background: #ffffff !important;
}

.filepond--image-preview-overlay-idle {
    color: rgba(255, 255, 255, 0.85) !important;
}

.filepond--image-preview-overlay-success {
    color: rgb(54, 151, 99);
}

.filepond--image-preview-overlay-failure {
    color: rgb(196, 78, 71);
}
.filepond--root,
.filepond--root .filepond--drop-label {
  min-height: 160px;
  background-color: #F1F0EF !important;
}

.filepond--panel{
    background-color: unset ;
}

.ck-editor .ck-editor__editable_inline {min-height: 140px !important;}

.form-label{
    font-weight: 500;
    font-size: 14px;
}

.form-label-table{
    font-weight: 500;
    font-size: 1rem;
}

.categorylink:hover {
    color: #F4F4F4 !important;
}
.wd-wrapper{
    padding-top: 2.5rem !important;
    width: 100% !important;
    height: 100vh !important;
}

.img-wrap {
    position: relative;
}
.close-btn{
    position: absolute;
    bottom : 5px;
    cursor: pointer;
    left: 5px;
}

.sidebar_active {
    width: 245px !important;
}

.react-confirm-alert-overlay{
    background: rgba(0, 0, 9, 0.1) !important;
}

  .show-icon{
      display: none;
  }

@media only screen and (max-width: 1150px) {
    .sidebar_wrap {
      width: 0px;
    }

    .wrapper-wx{
        padding-left:245px;
    }

    .show-icon{
        display: inline-block;
    }
  
  }



.admin_bofy {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 90vh;
	margin: -20px 0 50px;
}

.admin_bofy h1 {
	font-weight: bold;
	margin: 0;
}

.admin_bofy h2 {
	text-align: center;
}

.admin_bofy p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.admin_bofy span {
	font-size: 12px;
}

.admin_bofy a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.admin_bofy button {
	border-radius: 20px;
	border: 1px solid #03a83a;
	background-color: #03a83a;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: -webkit-transform 80ms ease-in;
	transition: transform 80ms ease-in;
	transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

.admin_bofy button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.admin_bofy button:focus {
	outline: none;
}

.admin_bofy button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.admin_bofy form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.admin_bofy input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.admin_bofy .container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	-webkit-animation: show 0.6s;
	        animation: show 0.6s;
}

@-webkit-keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

.overlay {
	background: #FF416C;
	background: linear-gradient(to right, #04991d, #8df392);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	-webkit-transform: translateX(0);
  	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	-webkit-transform: translateX(50%);
  	        transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-left {
	-webkit-transform: translateX(-20%);
	        transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.overlay-right {
	right: 0;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	-webkit-transform: translateX(20%);
	        transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.admin_footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

.admin_footer p {
    margin: 10px 0;
}

.admin_footer i {
    color: red;
}

.admin_footer a {
    color: #3c97bf;
    text-decoration: none;
}
